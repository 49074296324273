@import "../../styles/variables";

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li p {
  margin: 0;
  padding: 0;
}

.profilePic {
  padding-right: 1em;
}

p sub {
  bottom: 0 !important; // we're using sub as <small>
}

.connected-to {
  text-align: center;

  a {
    padding-left: 0.5em;
    padding-right: 0.5em;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:before {
      display: block;
      margin: 0 auto;
    }
  }
}

@import "../../styles/variables";

@mixin mobile {
  @media (max-width: 768px) {
    @content;
  }
}

.color-palette {
  position: relative;
  display: grid;
  width: 100%;
  height: 100vh;
  min-height: 37rem; // otherwise it will flow over each other

  /* Css grid built with https://cssgrid-generator.netlify.app/ */
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: repeat(17, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  justify-items: center;

  margin-bottom: 2em;

  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;

    background: url("../../assets/images/Robert-Jan\ -\ geel.png") no-repeat
      left bottom;
    background-size: 20%;
    border-bottom: 1px solid #000;

    @include mobile() {
      background-size: 40%;
    }
  }
}

.palette-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  color: var(--color-dark);
  height: 100%;
  width: 100%;
}

.palette-item--light {
  color: var(--color-light);
}

.palette-item:hover,
.palette-item:focus {
  transform: scale(1.05);
}

#pi-over {
  grid-area: 4/2/8/6;
  font-size: 0.9rem;

  .swatch-image {
    max-width: 6rem;
  }

  @include mobile() {
    grid-area: 4/1/8/7;
  }
}

#pi-business-mediation {
  grid-area: 7/4/12/15;
  font-size: 1.5rem;

  .swatch-image {
    max-width: 20rem;
  }

  @include mobile() {
    grid-area: 7/4/12/16;
    font-size: 1.2rem;

    .swatch-image {
      max-width: 12rem;
    }
  }
}

#pi-maatwerk {
  grid-area: 1/9/7/18;
  font-size: 0.9rem;

  .swatch-image {
    max-width: 10rem;
  }

  @include mobile() {
    grid-area: 1/7/7/18;
  }
}

#pi-contact {
  grid-area: 13/13/17/16;

  @include mobile() {
    grid-area: 13/11/17/16;
  }
}

#pi-voorwaarden-en-privacy {
  grid-area: 12/5/16/9;

  .swatch-heading {
    font-size: calc(0.4em + 1vw);
  }

  @include mobile() {
    grid-area: 12/6/16/11;
  }
}

.color-swatch {
  filter: url("#goo"); // to make sure the corners are rounded off
  z-index: 1;
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: var(--brush-cursor), auto;
}

.splotch {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.swatch-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-flow: column wrap;
  /* default margin */
  margin: 3vw;
  z-index: 2;
}

.swatch-heading {
  z-index: 2;
  font-size: calc(0.7em + 1vw);
  text-align: center;
  padding: 0 0.5em;
  font-family: var(--font-family-headings);

  @include mobile() {
    font-size: calc(0.7em + 0.9vw);
  }
}

.swatch-image {
  max-width: 7rem;
}

.swatch-body {
  display: none;
}

.logo {
  opacity: 0; /* Hide the logo initially */
}

.contactPageProfilePic {
  float: right;
  width: 40%;
}

@import "../../styles/variables";

.divider {
  box-sizing: border-box;
  width: 100%;
  clear: both;
  padding: 1rem 5vw;
}

.dividerInner {
  height: 0.05rem;
  width: 100%;
  background: var(--color-dark);
}

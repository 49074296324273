@mixin hrefBefore() {
  position: relative;
  display: inline-block;
  top: 0.15em;
  text-decoration: none;
  margin-right: 0.25em;
}

a[href^="mailto:"]:before {
  content: "📧";
  @include hrefBefore();
}
a[href^="tel:"]:before {
  content: "📱";
  @include hrefBefore();
}

@mixin hrefBeforeImage() {
  content: "";
  @include hrefBefore();

  top: 0.25em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain; // Ensure the image fits within the bounds
  height: 1.5em;
  width: 4em;
}
a[href$="mediatorsvereniging.nl/"]:before {
  background-image: url("assets/images/logo nmv mediatorsvereniging.png");

  @include hrefBeforeImage();
}

a[href$="mfnregister.nl/"]:before {
  background-image: url("assets/images/MfN_Registermediator.png");

  @include hrefBeforeImage();
}

a[href^="https://linkedin.com/"]:before
{
  background-image: url("assets/images/linkedin.png");

  @include hrefBeforeImage();
  height: 1em;
  width: 1.4em;
}

@import "../../styles/variables";

.gradientText {
  & > * {
    /* Create the gradient. */
    background-image: repeating-linear-gradient(
      45deg,
      var(--palette-color-2),
      var(--palette-color-4),
      var(--palette-color-5)
    );

    /* Set the background size and repeat properties. */
    background-size: 150%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  }

  &Light > * {
    /* Create the gradient. */
    background-image: repeating-linear-gradient(
      45deg,
      var(--palette-color-1),
      var(--palette-color-3)
    );
  }
}

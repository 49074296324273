@import "../../styles/variables";

.button {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  padding: 1rem;
  cursor: var(--brush-cursor), auto;
  color: var(--color-dark);

  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:hover {
    transform: scale(1.05);
  }

  &--light {
    color: var(--color-light) !important;
  }
}

@import "../../styles/variables";

.fallbackBackground {
  background-size: auto 400%;
  animation: gradient 3.5s ease; // a bit slower than the normal default animation. It's a fallback, after all
}
@keyframes gradient {
  from {
    background-position: 0% 100%;
  }
  to {
    background-position: 0% 0%;
  }
}

.contentLight,
.contentLight a {
  color: var(--color-light);
}

.modal {
  z-index: 9001;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative;

  animation: fadeInAnimation ease-in 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0rem;
  padding: 0.8rem;
  margin-top: -1rem;
  text-decoration: none;
  cursor: pointer;
  font-size: 2.5rem;
  color: inherit;
}

.content {
  margin: 0 0.8rem;
  max-height: 80%;
  max-width: 75rem;
  overflow: auto;
}

canvas.spilled-paint {
  z-index: 8000;
  position: absolute;
  inset: 0;
  pointer-events: none;
  visibility: hidden;
  opacity: 0.5;
  pointer-events: none;
}

body.displaying-modal canvas.spilled-paint {
  visibility: visible;
  opacity: 1;
}

.modalContainer {
  z-index: 9000;
  position: fixed;
  inset: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding: 1rem;
  }
}

.contentLight,
.contentLight a {
  color: var(--color-light);
}

@import "../../styles/variables";

.contactgegevens {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--palette-color-4);
  color: white !important;
  margin-bottom: 1rem;
  box-shadow: 1px 1px 5px #000;
  transition: 2s;
  min-height: 2.5em;

  ul {
    padding: 0;
    margin: 0;
    text-align: center;
  }

  li {
    list-style: none;
    display: inline-block;
    padding: 0.5rem;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

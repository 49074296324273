@import "../../styles/variables";

.readmoreBtn {
  display: flex;
  justify-content: center;
  margin-top: -0.75em;
}

.blogImg {
  width: auto;
  height: auto;
  max-height: 6rem;
  max-width: 6rem;
  padding: 0.5rem;
}

.blogListItem {
  p:last-child {
    margin-bottom: 0;
  }
  &:not(:last-child) {
    &:after {
      content: "";
      display: block;
      background: #ccc;
      height: 0.01rem;
      width: 70%;
      margin: 1em auto;
    }
  }
}

.display-all-blogs-button {
  display: block;
  background: var(--color-dark);
  color: var(--color-light) !important;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  padding: 0.2em;
}
